import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout2 from "../components/layout2"
import Footer from '../components/footer'
import './../css/thoughts.css'
import { Helmet } from "react-helmet"
import './../css/button.css'
import GIF from './../img/projects/ootb.gif'
import Apple from './../img/projects/apple_icon.svg'
import Google from './../img/projects/google_icon.png'

class Thoughts extends React.Component {

render() {
  return (
    <Layout2>
    <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "img/projects/ootb.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Out of the Blue">
  <meta name="description" content="Out of the Blue is a contact roulette app designed for a conversation first world." />
  <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Out of the Blue" />
  <meta property="og:description" content="Out of the Blue is a contact roulette app designed for a conversation first world." />
  <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
  <meta name="twitter:card" content="summary" />
  <meta name="twitter:title" content="Out of the Blue" />
  <meta name="twitter:description" content="Out of the Blue is a contact roulette app designed for a conversation first world." />
  <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>
    <div css={{width:`100%`, marginTop:`50px`,marginLeft:`auto`, marginRight:`auto`, maxWidth:`700px`, position:'relative'}}>
    <div css={{display:`flex`,marginBottom:`50px`, '@media (max-width: 750px)':{display:`block`}}}>
    <div css={{width:`45%`, '@media (max-width: 750px)':{width:`60%`, marginLeft:`auto`, marginRight:`auto`}}}>
    <img src={GIF} />
    </div>
    <div css={{display:`block`, maxWidth:`53%`, paddingLeft:`3%`,paddingRight:`10px`, '@media (max-width: 750px)':{width:`80%`, marginLeft:`auto`, marginRight:`auto`, maxWidth:`80%`}}}>
    <div css={{'@media (max-width: 750px)':{textAlign:`justify`}, '@media (max-width: 400px)':{textAlign:`left`}}}>
    <h2 css={{marginBottom:0,paddingTop:`20px`,marginLeft:`auto`, marginRight:`auto`, '@media (max-width: 750px)':{textAlign:`center`}}}> Out of the Blue </h2>
    <h5 css={{'@media (max-width: 750px)':{textAlign:`center`},fontFamily:`Cooper Hewitt`, marginTop:`10px`}}><i>a roulette for your contacts</i></h5>
    <span>Social media changed the way we communicate. We shifted from heartfelt conversations to passive likes.<br/><br/>
    This is my attempt to change that.<br/><br/>Next time you have the urge to go on social media, use this tool to generate a random contact and reconnect with them via conversation, not just a passive like.<br/><br/>Download it for free today!</span><br/><br/>
    <div css={{display:`flex`}}>
    <a href="https://apps.apple.com/us/app/out-of-the-blue-app/id1549417815?itsct=apps_box&amp;itscg=30200" style={{marginLeft:`-20px`,width:`50%`,float:`left`,display:`inlineBlock`, overflow:`hidden`, borderRadius:`13px`, height:`63px`}}><img src={Apple} alt="Download on the App Store" style={{marginTop:`10px`,borderRadius:`13px`, width: `164px`, height:`43px`}}/></a>
    </div>
    </div>
    </div>
    </div>
    <Footer/>
    </div>

    </Layout2>
)
}

}

export default Thoughts;
