import React from "react"
import Layout2 from "../components/layout2"
import Button from './../elements/button.js'
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import Footer from "./../components/footer.js"
import OOTB from './out-of-the-blue.js'
export default () =>

<StaticQuery
  query={graphql`
    query {
      file(relativePath: { eq: "img/404/senior_pic.jpg" }) {
        childImageSharp {
          fluid(maxWidth:2000) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `}
  render={data => (
    <OOTB/>
  )}
/>
