import React from "react"
import NavBar from './../elements/navbar.js'
import Footer from './../components/footer.js'
import './../css/layout.css'
export default ({ children }) => (

  <div css = {{margin:'0', padding:'0', overflowX:'hidden', overflowY:'hidden'}}>
  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
  <NavBar/>
  <div css = {{minWidth:`300px`}}>

  <div>
    {children}
  </div>
  </div>
  <div css = {{background:"#202020"}}>
  </div>
  </div>

)
